<template>
  <div class="w-100 p-l-30 p-r-30">
    <div class="contents-header">
      <p class="breadcrumb-menu"><a class="ashen-link" @click="$router.push('account')">アカウント設定</a><i
          class="fas fa-angle-right form-control-color"></i> パスワード変更</p>
    </div>
    <div class="card p-5 text-first">
      <div class="card-body mx-auto">
        <div class="row">
          <h3 class="fw-bold mb-4">パスワード変更</h3>
          <div class="col">
            <label for="exampleInputPassword1">現在のパスワード</label>
            <input v-model="oldPassword" class="form-control" type="password">
            <div v-show="errors['oldPassword']" id="err_password_old" class="form-error">{{
                errors['oldPassword']
              }}
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">新しいパスワード</label>
            <input v-model="newPassword" class="form-control" placeholder="半角英数字・記号、8〜20文字" type="password">
            <div v-show="errors['newPassword']" id="err_password_new" class="form-error">{{
                errors['newPassword']
              }}
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">新しいパスワードの確認</label>
            <input v-model="newPasswordConfirm" class="form-control" type="password">
            <div v-show="errors['newPasswordConfirm']" id="err_password_confirm" class="form-error">
              {{ errors['newPasswordConfirm'] }}
            </div>
          </div>
        </div>
        <div class="row mt-4 d-flex justify-content-between">
          <div class="col">
            <button class="btn btn-light bg-white ashen-link text-decoration-none light border-0 w-100 mt-4"
                    @click="$router.push('account')">キャンセル
            </button>
          </div>
          <div class="col">
            <button class="btn col btn-primary btn-outline-light font-weight-bold w-100 mt-4" v-on:click="doChange">変更
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import {SetAdminChangePassword} from "jsadminclient";
import Common from "jsadminclient/common";
import {mapGetters} from "vuex";

export default {
  name: "EditPasswordRequired",
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      errors: []
    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  methods: {
    async doChange(step) {
      this.errors = [];

      const user_id = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        var params = new SetAdminChangePassword()
        params.oldPassword = this.oldPassword
        params.newPassword = this.newPassword
        params.newPasswordConfirm = this.newPasswordConfirm
        const jsonObject = await api.usersUserIdChangePasswordPostAsync(user_id, params)

        Common.showToast("パスワードが変更されました。")
        this.$router.push({name: 'AccountSettings'});
      } catch (error) {
        Common.getErrorList(error.response, this.errors)
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
  },
}

</script>

<style lang="scss" scoped>
.form-control {
  background-color: #F2ECF0;
  border: 1px solid #F2ECF0;
  height: 44px;
}

.form-error {
  max-width: 440px;
}

::placeholder {
  color: #E0B1B7;
}

.container {
  margin-bottom: 6rem;
}

.fa-angle-right {
  width: 15px;
  height: auto;
  color: #888888;
}

.ok {
  width: 255px;
  height: 44px;
  color: white;
  background-color: #C95D6C;
}

.ok:active {
  color: white;
  background-color: #C95D6C;
}

.cancel {
  width: 255px;
  height: 44px;
  color: #C95D6C;
  border: none;
  background-color: #FFFFFF;
}

.cancel:active {
  color: #C95D6C;
  background-color: #FFFFFF;
}

h3 {
  font-size: 30px;
}

a {
  color: #333333;
}


</style>
